<template>
  <section>
    <div id="info">
      <img id="logo" src="@/assets/img/logo.png" alt="FISFAC logo" />

      <img
        id="coming-mobile"
        src="@/assets/img/mobile-contruccion.png"
        alt=""
      />
      <p>
        El sitio estará listo pronto.
      </p>
      <strong>
        Contacto e información:
      </strong>
      <span>
        (+52) 312 323 5484
      </span>
    </div>
    <div id="background" />
  </section>
</template>

<script>
export default {
  setup() {
    return {};
  }
};
</script>
<style lang="sass" scoped>
section
  min-height: 100vh
  display: flex
  align-items: center
  #info
    width: 100%
    max-width: 500px
    font-size: 20px
    margin: -50px 0 0 50px
    z-index: 2
    border-radius: 70px
    background: rgba(255,255,255,.5)
    #logo
      width: 90%
      max-width: 300px
    #coming-mobile
      display: none
    p
      font-size: 18px
      padding: 0 30px
    span
      white-space: nowrap
  #background
    position: absolute
    top: 0
    right: 0
    // border-left: 1px solid red
    width: calc(100% - 400px)
    height: 100%
    background-image: url(../assets/img/enConstruccion.svg)
    background-size: contain
    background-position: top right;
    background-repeat: no-repeat;

@media screen and (min-width: 1300px)
  section
    #info
      margin-left: 100px
@media screen and (max-width: 1299px) and (min-width: 800px)
  section
    #background
      width: calc(100% - 200px)
    #info
      width: 300px
      margin: -50px 0 0 50px
@media screen and (max-width: 799px)
  section
    #info
      max-width: 100%
      margin: 20px 0
      #coming-mobile
        display: block
        width: 100%
    #background
      display: none
</style>
